import React from "react";
import Preloader from "./components/Preloader/Preloader";
import CountDown from "./components/countdown/CountDown";

import "./App.css";
import logo from "./logo.png"

function App() {
  return (
    <div className="App">
      <div className="container">
        <img height={300} src={logo}  alt="Logo digireporting" />
        <div className="content">
          <h1>Coming Soon</h1>
            <CountDown />
            <Preloader />
          </div>
      </div>
    </div>
  );
}

export default App;
